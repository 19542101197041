<template>
    <div>
        <!-- Filters -->
        <!-- <play-list-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchContest" /> -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePagePlaylist({ page: 1, itemsPerPage: $event })" />
                        <label>entries</label>
                    </b-col>
                </b-row>
            </div>

            <b-table ref="refUserListTable" class="position-relative pb-3" :items="contests" :per-page="perPage" responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <!-- Column: Music -->
                <template #cell(name)="data">
                    <b-media vertical-align="center">
                        <template #aside> </template>
                        <b-link :to="{ name: 'apps-genres-view', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
                            {{ data.item.name }}
                        </b-link>
                        <small v-if="data.item.name_ar" class="text-muted">{{ data.item.name_ar }}</small>
                    </b-media>
                </template>
                <template #cell(stages)="data">
                    <b-badge
                        variant="light-success"
                        :to="{
                            name: 'apps-contest-stage-add',
                            params: { contestId: data.item.id },
                        }"
                    >
                        Add
                    </b-badge>
                    <b-badge
                        variant="light-danger"
                        class="mx-50"
                        :to="{
                            name: 'apps-contest-stage-list',
                            params: { contestId: data.item.id },
                        }"
                    >
                        List
                    </b-badge>
                </template>
                <template #cell(groups)="data">
                    <b-badge
                        variant="light-success"
                        :to="{
                            name: 'apps-contest-group-add',
                            params: { contestId: data.item.id },
                        }"
                    >
                        Add
                    </b-badge>
                    <b-badge
                        variant="light-danger"
                        class="mx-50"
                        :to="{
                            name: 'apps-contest-group-list',
                            params: { contestId: data.item.id },
                        }"
                    >
                        List
                    </b-badge>
                </template>
                <template #cell(status)="data">
                    <b-badge variant="success" v-if="data.item.is_active == 1"> Active </b-badge>
                    <b-badge variant="danger" v-else> Not active </b-badge>
                </template>
                <template #cell(candidates_select)="data">
                    <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="navigateTo(data.item.id)"
                    >
                        Select
                    </b-button>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item
                            :to="{
                                name: 'apps-contest-edit',
                                params: { id: data.item.id },
                            }"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteContest(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <!-- <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                    </b-col>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePagePlaylist({ page: $event, itemsPerPage: perPage })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div> -->
        </b-card>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import PlayListListFilters from "./PlayListListFilters.vue";
import userStoreModule from "../user/userStoreModule";
import genresRepository from "@/abstraction/repository/genresRepository";
import useContestList from "./useContestList";
import Ripple from "vue-ripple-directive";
import router from "@/router";
const genreRepository = new genresRepository();
export default {
    directives: {
        Ripple,
    },
    components: {
        PlayListListFilters,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const {
            perPageOptions,
            contests,
            pagination,
            perPage,
            sortBy,
            tableColumns,
            currentPage,
            fetchContest,
            deleteContest,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useContestList();

        const USER_APP_STORE_MODULE_NAME = "app-user";
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];

        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
        ];

        onMounted(() => {
            fetchContest().then((res) => {
                res.value.map((item) => {
                    return item.created_at.substring(0, 10);
                });
                // console.log(
                //     res.value.map((item) => {
                //         return item.created_at;
                //     })
                // );
                for (let i = 0; i < res.value.length; i++) {
                    const element = res.value[i];
                    console.log(element);
                    element.created_at = element.created_at.substring(0, 10);
                }
                // res.value.created_at = res.value.map((item) => {
                //     return item.created_at.substring(0, 10);
                // });
            });
        });

        const changePagePlaylist = async (paginate) => {
            await fetchContest(paginate);
        };

        const updateSort = async (id) => {
            await genreRepository.updateSort(id);
            await fetchContest({ page: currentPage, perPage: perPage });
        };
        const navigateTo = (id)=>{
            router.push(`/apps/contest-participant/list/${id}`);
        }
        return {
            contests,
            pagination,
            // Filter
            avatarText,
            roleOptions,
            planOptions,
            statusOptions,
            perPageOptions,
            changePagePlaylist,
            fetchContest,
            perPage,
            currentPage,
            sortBy,
            tableColumns,
            deleteContest,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            updateSort,
            navigateTo,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
