var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('b-form-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},on:{"change":function($event){return _vm.changePagePlaylist({ page: 1, itemsPerPage: $event })}},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entries")])],1)],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative pb-3",attrs:{"items":_vm.contests,"per-page":_vm.perPage,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return undefined},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'apps-genres-view', params: { id: data.item.id } }}},[_vm._v(" "+_vm._s(data.item.name)+" ")]),(data.item.name_ar)?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.name_ar))]):_vm._e()],1)]}},{key:"cell(stages)",fn:function(data){return [_c('b-badge',{attrs:{"variant":"light-success","to":{
                        name: 'apps-contest-stage-add',
                        params: { contestId: data.item.id },
                    }}},[_vm._v(" Add ")]),_c('b-badge',{staticClass:"mx-50",attrs:{"variant":"light-danger","to":{
                        name: 'apps-contest-stage-list',
                        params: { contestId: data.item.id },
                    }}},[_vm._v(" List ")])]}},{key:"cell(groups)",fn:function(data){return [_c('b-badge',{attrs:{"variant":"light-success","to":{
                        name: 'apps-contest-group-add',
                        params: { contestId: data.item.id },
                    }}},[_vm._v(" Add ")]),_c('b-badge',{staticClass:"mx-50",attrs:{"variant":"light-danger","to":{
                        name: 'apps-contest-group-list',
                        params: { contestId: data.item.id },
                    }}},[_vm._v(" List ")])]}},{key:"cell(status)",fn:function(data){return [(data.item.is_active == 1)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" Active ")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(" Not active ")])]}},{key:"cell(candidates_select)",fn:function(data){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.navigateTo(data.item.id)}}},[_vm._v(" Select ")])]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                            name: 'apps-contest-edit',
                            params: { id: data.item.id },
                        }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteContest(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }